body {
  background: #fff;
  font-size: 12px;
  font-family: var(--ff-primary);
}
h1 {
  font-size: 1rem;
}

.HrmAppPage {
  margin-top: 15px;
  margin-bottom: 120px;
}
.HrmAppHomePage {
  margin-top: 70px;
}
.HrmAppHeaderProfileWrapper {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  margin-bottom: 10px;
}
.HrmAppHeaderProfileDetails {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  background: #ffffff;
  padding: 10px 10px;
  border-radius: 5px;
}
.HrmAppHeaderProfileDetails img {
  margin-right: 10px;
  max-width: 45px;
}
.HrmAppHeaderProfileName {
  font-size: 14px;
  font-weight: bold;
  text-transform: capitalize;
}
.HrmAppHeaderProfileDesignation {
  color: #858585;
  font-size: 12px;
}
.HrmAppBanner {
  margin-bottom: 15px;
  box-shadow: 0px 4px 4px rgba(229, 229, 229, 0.51);
}
.HrmAppHomeListWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  font-size: 12px;
  gap: 10px;
  margin-top: 0;
}
.HrmAppHomeListWrapper > div {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.HrmAppHomeListItem {
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1px 2px rgb(0 0 0 / 16%), 0px 2px 4px rgb(0 0 0 / 12%),
    0px 1px 8px rgb(0 0 0 / 10%);
  background: #fff;
  border-radius: 8px;
  margin-bottom: 10px;
  width: 50px;
}
.HrmAppHomeListItem p {
  margin-bottom: 0;
  font-weight: 500;
}
.HrmAppHomeListItem img {
}
.HrmAppHomePageButtonWrapper {
  padding: 10px;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 60px;
  z-index: 99;
}

/* Attendance */
.sdp {
  margin: 0 auto;
  width: 100% !important;
  margin-bottom: 10px;
}
.sdp--date-btn__selected {
  background-color: #fc4f4f !important;
}
.timelineDot {
  border-radius: 25px;
  font-size: 9px;
  text-align: center;
  color: #fff;
  line-height: 20px;
  padding: 0 5px;
}
.timelineDot.apsend {
  background: red;
}
.timelineDot.present {
  background: green;
}
.timelineDot.noupdate {
  background: #aaa;
}

/* Leaves  */
.leaveListPendingCard {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  border: 0;
  width: 100%;
  text-align: left;
  font-family: var(--ff-primary);
  box-shadow: 0px 1px 2px rgb(0 0 0 / 16%), 0px 2px 4px rgb(0 0 0 / 12%),
    0px 1px 8px rgb(0 0 0 / 10%);
  margin-top: 10px;
}
.leaveListPendingCardHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 5px;
  width: 100%;
}
.leaveListPendingCardProfile {
  display: flex;
}
.leaveListPendingCardProfile img {
  margin-right: 10px;
  max-width: 45px;
}
.leaveListPendingCardProfileName {
  font-size: 14px;
  font-weight: bold;
  text-transform: capitalize;
}
.leaveListPendingCardProfileDesignation {
  color: #858585;
  font-size: 12px;
}
.leaveListPendingCardLeavetype {
  font-size: 9px;
  background-color: green;
  padding: 3px 10px;
  color: #fff;
  border-radius: 25px;
}
.leaveListPendingCardBody {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 5px;
  padding-top: 5px;
  margin-bottom: 5px;
  width: 100%;
}
.leaveListPendingCardFooter {
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 5px;
  padding-top: 5px;
  margin-bottom: 5px;
  width: 100%;
}
.leaveListPendingCardBody > div {
  flex: 1;
}
.leaveListPendingCardButtonWrap {
  display: flex;
  gap: 15px;
}
.leaveRejectedIndicator {
  border-radius: 25px;
  background: red;
  color: #fff;
  padding: 3px 5px;
  font-size: 9px;
  text-transform: capitalize;
}
.leaveApprovedIndicator {
  border-radius: 25px;
  background: green;
  color: #fff;
  padding: 3px 5px;
  font-size: 9px;
  text-transform: capitalize;
}
.leaveCreateFormWrapper {
  margin-top: 15px;
}
.leaveCreateButtonWrapper {
  display: inline-block;
  margin: 15px 0;
}
.leaveSetupCard {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  border: 0;
  width: 100%;
  text-align: left;
  font-family: var(--ff-primary);
  margin-top: 10px;
  box-shadow: 0px 1px 2px rgb(0 0 0 / 16%), 0px 2px 4px rgb(0 0 0 / 12%),
    0px 1px 8px rgb(0 0 0 / 10%);
}
.leaveSetupCardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.InfoLabel {
  font-size: 10px;
  color: #666;
  text-transform: capitalize;
}
.InfoData {
  font-size: 12px;
  color: #333;
  font-weight: 500;
}

.searchWrapper {
  margin-bottom: 15px;
}

/* attendancelog */

.attendanceLogCard {
  width: 90%;
  margin: 0 auto 21px auto;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 10px 10px;
}

.attendanceLogCardHead {
  display: flex;
  justify-content: space-between;
  padding: 7px 10px;
  background: #f0f0f0;
}
.attendanceLogCardHeadDate {
  font-weight: 600;
  font-size: 12px;
  color: #333333;
}
.attendanceLogCardHeadOff {
  font-weight: 600;
  font-size: 12px;
  color: #666666;
}
.attendanceLogCardContent {
  display: flex;
  justify-content: space-between;
  padding: 10px 9px 15px 9px;
}
.attendanceLogBox:last-child {
  border-right: none;
}
.attendanceLogBox:first-child {
  padding-left: 0px;
}
.attendanceLogBoxTitle {
  font-weight: 400;
  font-size: 12px;
  color: #bdbdbd;
  margin-bottom: 3px;
}
.attendanceLogBoxValue {
  font-weight: 400;
  font-size: 12px;
  color: #969696;
}
.attendanceLogSortWrap .MuiInput-underline:before,
.attendanceLogSortWrap .MuiInput-underline:hover:not(.Mui-disabled):before,
.attendanceLogSortWrap .MuiInput-underline:after {
  border-bottom: none;
}
.attendanceLogSortWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: 20px auto;
}
.attendanceLogDateSelect.MuiFormControl-root {
  background: #5db075;
  mix-blend-mode: normal;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 100px;
}
.attendanceLogDateSelect .MuiSelect-select.MuiSelect-select {
  font-weight: 600;
  font-size: 12px;
  color: #fff;
  padding: 9px 12px 9px 9px;
  min-width: 70px;
}
.attendanceLogDateSelect .MuiSelect-icon {
  color: #fff;
  right: 3px;
}
.attendanceLogViewSelect.MuiFormControl-root {
  background: #ffffff;
  mix-blend-mode: normal;
  border: 2px solid #5db075;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 100px;
}
.attendanceLogViewSelect .MuiSelect-select.MuiSelect-select {
  font-weight: 600;
  font-size: 12px;
  color: #5db075;
  padding: 7px 12px 7px 9px;
}
.attendanceLogViewSelect .MuiSelect-icon {
  color: #5db075;
  right: 3px;
}

/* Payroll */
.payRollComponent {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e1e1e1;
  align-items: center;
  padding: 0px 10px;
  min-height: 35px;
}
.payRollComponentName {
  font-weight: bold;
  color: #000;
  flex: 1;
}
.payRollComponentValue {
  flex: 1;
}
.addPayRollComponent {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  border-bottom: 1px solid #e1e1e1;
  grid-gap: 10px;
  gap: 5px;
  align-items: center;
  text-align: center;
}
.addPayRollComponent > div:last-child {
  margin-left: 15px;
}
.payrollCardInfoGreen {
  color: #5db075;
}
.payrollCardInfoRed {
  color: #fc4f4f;
}

.payrollProfileCard {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  border: 0;
  width: 100%;
  text-align: left;
  font-family: var(--ff-primary);
  box-shadow: 0px 1px 2px rgb(0 0 0 / 16%), 0px 2px 4px rgb(0 0 0 / 12%),
    0px 1px 8px rgb(0 0 0 / 10%);
  margin-top: 10px;
}
.payrollProfileCardHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 15px;
  width: 100%;
}
.payrollProfileCardBody {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.payslipDownloadContainer {
  text-align: center;
  margin-bottom: 50px;
}
.payslipDownloadContainer img {
  margin: 30px auto;
  width: 50px;
}

.edit_option_btn_drawer .MuiDrawer-paper {
  border-radius: 8px 8px 0px 0px;
}
.edit_option_head {
  background: #9d00c6;
  padding: 12px 17px 12px 17px;
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
  text-transform: capitalize;
}
.edit_option_tools_wrap {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding-left: 0;
  padding: 15px 25px;
  margin: 0;
}
.edit_option_tools_item {
  width: 33%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #8c8ca2;
  margin-bottom: 10px;
}
.edit_option_tools_item_text {
  font-weight: 400;
  font-size: 12px;
  text-transform: capitalize;
  margin-top: 4px;
}
.AppLabel {
  font-weight: 400;
  font-size: 12px;
  color: #8c8ca2;
}
.profile_top {
  display: flex;
  justify-content: space-between;
  background: #fff;
  padding: 15px 15px;
}
.profile_left {
  display: flex;
  flex: 1 1;
}
.profile_img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  min-width: 40px;
}
.profile_details {
  margin-left: 10px;
}
.profile_name {
  font-weight: 500;
  font-size: 14px;
  color: #0e0e2c;
  margin-top: 0;
  margin-bottom: 4px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-transform: capitalize;
}
.profile_company_name {
  font-weight: 400;
  font-size: 12px;
  color: #fc5252;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.option_btn {
  border: none;
  background: transparent;
  color: #4a4a68;
  font-size: 20px;
  min-width: 50px;
}
.option_btn svg {
  font-size: 35px;
}
.staff_profile_datepicker_wrap {
  margin-bottom: 30px;
  background: #fff;
  padding: 20px 10px;
  border-radius: 5px;
}
.hrm_settings_btn_wrap {
  margin-left: auto;
}
.w_box {
  background: #ffffff;
  padding: 10px 10px;
  border-radius: 5px;
}
.mb {
  margin-bottom: 10px;
}
.w_100 {
  width: 100%;
}
